// **************** FONTS *****************
// Megaland
@font-face {
  font-family: "Megaland";
  src: local("Megaland"), url(./assets/fonts/Magland-DO3zx.ttf) format("truetype");
}

.font-megaland {
  font-family: "Megaland";
}

// OpenSans-Bold
@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"), url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
}

.font-open-sans-bold {
  font-family: "OpenSans-Bold";
}

// OpenSans-Bold-Italic
@font-face {
  font-family: "OpenSans-Bold-Italic";
  src: local("OpenSans-Bold"), url(./assets/fonts/OpenSans-BoldItalic.ttf) format("truetype");
}

.font-open-sans-bold-italic {
  font-family: "OpenSans-Bold-Italic";
}

// OpenSans-ExtraBold
@font-face {
  font-family: "OpenSans-ExtraBold";
  src: local("OpenSans-ExtraBold"), url(./assets/fonts/OpenSans-ExtraBold.ttf) format("truetype");
}

.font-open-sans-extra-bold {
  font-family: "OpenSans-ExtraBold";
}

// OpenSans-Italic
@font-face {
  font-family: "OpenSans-Italic";
  src: local("OpenSans-Italic"), url(./assets/fonts/OpenSans-Italic.ttf) format("truetype");
}

.font-open-sans-italic {
  font-family: "OpenSans-Italic";
}

// OpenSans-Light
@font-face {
  font-family: "OpenSans-Light";
  src: local("OpenSans-Light"), url(./assets/fonts/OpenSans-Light.ttf) format("truetype");
}

.font-open-sans-light {
  font-family: "OpenSans-Light";
}

// OpenSans-Light-Italic
@font-face {
  font-family: "OpenSans-LightItalic";
  src: local("OpenSans-LightItalic"), url(./assets/fonts/OpenSans-LightItalic.ttf) format("truetype");
}

.font-open-sans-light-italic {
  font-family: "OpenSans-LightItalic";
}

// OpenSans-Regular
@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"), url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

.font-open-sans-regular {
  font-family: "OpenSans-Regular";
}

// OpenSans-Semibold
@font-face {
  font-family: "OpenSans-Semibold";
  src: local("OpenSans-Semibold"), url(./assets/fonts/OpenSans-Semibold.ttf) format("truetype");
}

.font-open-sans-semibold {
  font-family: "OpenSans-Semibold";
}

// OpenSans-SemiboldItalic
@font-face {
  font-family: "OpenSans-SemiboldItalic";
  src: local("OpenSans-SemiboldItalic"), url(./assets/fonts/OpenSans-SemiboldItalic.ttf) format("truetype");
}

.font-open-sans-semibold-italic {
  font-family: "OpenSans-SemiboldItalic";
}

// Soulmaze
@font-face {
  font-family: "Soulmaze";
  src: local("Soulmaze"), url(./assets/fonts/MADE-Soulmaze-Brush.otf) format("opentype");
}

.font-soulmaze {
  font-family: "Soulmaze";
}

// Quentin
@font-face {
  font-family: "Quentin";
  src: local("Quentin"), url(./assets/fonts/Quentin.otf) format("opentype");
}

.font-quentin {
  font-family: "Quentin";
}

// CenturyGothic
@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic"), url(./assets/fonts/CenturyGothic.ttc) format("truetype");
}

.font-century-gothic {
  font-family: "CenturyGothic";
}


// **************** End Fonts ******************
